import React from "react";

import { Wizard } from "components/controls";
import ActionFooter from "components/controls/wizard/Footers/ActionFooter";

import ThemedSimpleLabel from "../../../ThemedSimpleLabel";
import IconStepper from "../../ThemedIconStepper";
import { StyledFormConfirmButton, StyledToolbar } from "../../../../../../controls";

import PageInfo from "./PageInfo";
import PageDatetimeLocation from "../../Common/PageDatetimeLocation";
import PageComments from "./PageComments";
import PageOverview from "./PageOverview";
import PageParty from "./PageParty";
import PagePictures from "./PagePictures";

const C = "profiles.hdi-at.PageClaim.PageCollision";

const FormCollision =
	({ substep, cancel, actions, item, images, onClearForm }) =>
	({ T, formProps: { isSubmitting, submitForm } }) => {
		const SimpleToolbar = () => (
			<StyledToolbar
				title={T(C, "Person.title")}
				actions={
					<StyledFormConfirmButton icon="delete" confirmIcon="delete" action={onClearForm} />
				}
			/>
		);

		return (
			<Wizard
				HeaderComponent={SimpleToolbar}
				LabelComponent={ThemedSimpleLabel}
				StepperComponent={IconStepper}
				FooterComponent={ActionFooter}
				labelPosition="above"
				initialStepIndex={substep}
				T={T}
				actions={actions}
			>
				<PageDatetimeLocation
					icon="event"
					label={T(C, "Common.PageDatetimeLocation.label")}
					cancel={cancel}
				/>
				<PageInfo icon="warning" label={T(C, "Person.PageInfo.label")} />
				<PageParty
					icon="person"
					label={T(C, "Person.PageParty.label")}
					item={item}
					images={images}
				/>
				<PagePictures
					icon="camera_alt"
					label={T(C, "Person.PagePictures.label")}
					item={item}
					images={images}
				/>
				<PageComments icon="edit" label={T(C, "Person.PageComments.label")} />
				<PageOverview
					icon="assignment_turned_in"
					label={T(C, "Person.PageOverview.label")}
					images={images}
					isSubmitting={isSubmitting}
					submitForm={submitForm}
				/>
			</Wizard>
		);
	};

export default FormCollision;
