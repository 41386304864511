import React from "react";
import { Form, Field } from "formik";
import * as Yup from "yup";

import { FormContainer } from "components/forms";
import {
	StyledButton,
	StyledFormTextField,
	StyledFormRadioGroup,
	StyledImagesUploader,
} from "../../../../../../controls";
import { useFormState } from "components/hooks";
import ThemedPageWrapper from "../../ThemedPageWrapper";

const C = "profiles.hdi-at.PageClaim.PageCollision.Car.PageParty";

const PagePartyForm =
	({ actions, item, images, onChanged }) =>
	({ formProps: { values } }) => {
		const controls = [
			{ label: "bekannt", value: "manual" },
			{ label: "unbekannt", value: "unknown" },
		];

		const partyIsKnown = values.partyKnown === "manual";

		return (
			<ThemedPageWrapper>
				<Form>
					<Field
						type="text"
						name="partyPlate"
						label="Kennzeichen des anderen Fahrzeugs"
						placeholder="Bitte ausfüllen falls bekannt..."
						component={StyledFormTextField}
					/>
					<Field
						type="text"
						name="partyKnown"
						label="Kontaktdaten der anderen Person"
						component={StyledFormRadioGroup}
						controls={controls}
						onChanged={(value) => onChanged({ ...values, ...value })}
					/>
					{partyIsKnown && (
						<>
							<Field
								name="images_license"
								label="Foto des Führerscheins/Ausweises:"
								actions={actions}
								item={item}
								images={images}
								tag="license"
								height="80px"
								component={StyledImagesUploader}
							/>
							<Field
								type="text"
								name="partyName"
								label="Name, Vorname"
								placeholder=""
								component={StyledFormTextField}
							/>
							<Field
								type="text"
								name="partyAddress"
								label="Adresse"
								placeholder=""
								component={StyledFormTextField}
							/>
							<Field
								type="text"
								name="partyTelno"
								label="Telefonnummer"
								placeholder=""
								component={StyledFormTextField}
							/>
							<Field
								type="text"
								name="partyEmail"
								label="Emailadresse"
								placeholder=""
								component={StyledFormTextField}
							/>
							<Field
								type="text"
								name="partyInsurer"
								label="Versicherer der anderen Person"
								placeholder=""
								component={StyledFormTextField}
							/>
						</>
					)}
				</Form>
			</ThemedPageWrapper>
		);
	};

const validationSchema = Yup.object().shape({
	partyKnown: Yup.string().required("Bitte geben Sie an, ob Ihnen die andere Person bekannt ist."),
	partyPlate: Yup.string(),
	partyName: Yup.string(),
	partyAddress: Yup.string(),
	partyTelno: Yup.string(),
	partyEmail: Yup.string().trim().email("Bitte geben Sie eine gültige Emailadresse an."),
	partyInsurer: Yup.string(),
});

const PageParty = ({ wizardProps: { T, actions }, item, images }) => {
	const [formState, dispatch] = useFormState();
	const { partyKnown, partyPlate, partyName, partyAddress, partyTelno, partyEmail, partyInsurer } =
		formState;
	const initialValues = {
		partyKnown,
		partyPlate,
		partyName,
		partyAddress,
		partyTelno,
		partyEmail,
		partyInsurer,
	};

	const onChanged = (values) => dispatch({ type: "FORM_CHANGE_VALUES", ...values });
	const FormComponent = PagePartyForm({ actions, item, images, onChanged });

	const onPrevious = () => dispatch({ type: "FORM_GOTO_PREVIOUS_SUBSTEP" });
	const onSubmit = (values) => {
		dispatch({ type: "FORM_SUBMIT_SUBSTEP_COLLISION_CAR_PARTY", ...values });
		actions.gtmCustomEvent({ eventAction: `journey:${C}:submit` });
	};

	return (
		<FormContainer
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			FormComponent={FormComponent}
			actionButtons={({ formProps: { submitForm } }) => [
				<StyledButton styled="secondary" key="cancel" onClick={onPrevious}>
					{T(C, "previous")}
				</StyledButton>,
				<StyledButton styled="primary" key="next" onClick={submitForm}>
					{T(C, "next")}
				</StyledButton>,
			]}
		/>
	);
};

export default PageParty;
