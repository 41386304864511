import React from "react";

import { Wizard } from "components/controls";
import ActionFooter from "components/controls/wizard/Footers/ActionFooter";

import ThemedSimpleLabel from "../../ThemedSimpleLabel";
import IconStepper from "../ThemedIconStepper";
import { StyledFormConfirmButton, StyledToolbar } from "../../../../../controls";

import PageDatetimeLocation from "../Common/PageDatetimeLocation";
import PageComments from "./PageComments";
import PageOverview from "./PageOverview";
import PagePictures from "./PagePictures";

const C = "profiles.hdi-at.PageClaim.PageOther";

const FormOther =
	({ substep, cancel, actions, item, images, onClearForm }) =>
	({ T, formProps: { isSubmitting, submitForm } }) => {
		const SimpleToolbar = () => (
			<StyledToolbar
				title={T(C, "title")}
				actions={
					<StyledFormConfirmButton icon="delete" confirmIcon="delete" action={onClearForm} />
				}
			/>
		);

		return (
			<Wizard
				HeaderComponent={SimpleToolbar}
				LabelComponent={ThemedSimpleLabel}
				StepperComponent={IconStepper}
				FooterComponent={ActionFooter}
				labelPosition="above"
				initialStepIndex={substep}
				T={T}
				actions={actions}
			>
				<PageDatetimeLocation
					icon="event"
					label={T(C, "Common.PageDatetimeLocation.label")}
					cancel={cancel}
				/>
				<PagePictures
					icon="camera_alt"
					label={T(C, "PagePictures.label")}
					item={item}
					images={images}
				/>
				<PageComments icon="edit" label={T(C, "PageComments.label")} />
				<PageOverview
					icon="assignment_turned_in"
					label={T(C, "PageOverview.label")}
					images={images}
					isSubmitting={isSubmitting}
					submitForm={submitForm}
				/>
			</Wizard>
		);
	};

export default FormOther;
