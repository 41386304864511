import React from "react";

import { Link } from "components/utils";

import SlaveFooter from "../../../../common/StickerFooter";
import css from "./PageInactive.module.scss";

const C = "profiles.hdi-at.Slave";

const PageInactive = ({ T, bundle, sticker }) => {
	const id = bundle.id + "/" + sticker.id;
	const status = `stickerStatus.${sticker.status}`;

	return (
		<div className={css.container}>
			<div className={css.infoBoxContainer}>
				<div className="infoBoxPrimary">
					{T(C, status, { id })}
					<br />
					<br />
					<Link
						className={css.link}
						title={T(C, `${status}.title`)}
						href={T(C, `${status}.href`)}
						data-gtm-action={`${C}:StickerInactive`}
					/>
				</div>
			</div>
			<SlaveFooter />
		</div>
	);
};

export default PageInactive;
