import React, { PureComponent } from "react";

import { Tab, Tabs, TabsContainer, Toolbar } from "lib/react-md";
import { Link } from "components/utils";
import { withIntl } from "components/hocs";

import css from "./PortalInactive.module.scss";
import "../../styles.scss"; // profile-wide styles

const C = "profiles.hdi-at.portals.default";

class PortalInactive extends PureComponent {
	render() {
		const { T, locale, portal } = this.props;

		const Logo = (
			<div className={css.logo}>
				<img src={require("../../img/hdi-logo.svg")} alt="logo" />
				{/*<Link
					title={<img src={require('../../img/hdi-logo.svg')} alt="logo"/>}
					href={T(C, "link.aboutus.href")}
				/>*/}
			</div>
		);

		const Header = <Toolbar className={css.toolbar} title="" actions={Logo} zDepth={0} />;

		const status = `portalStatus.${portal.status}`;

		return (
			<TabsContainer
				className={css.container}
				colored
				toolbar={Header}
				swipeableViewsProps={{
					disabled: true,
					springConfig: { duration: "0s", easeFunction: "...", delay: "0s" },
				}}
				swipeableViewsClassName={css.swipeableViewsContainer}
			>
				<Tabs tabId={`tabs-${locale}`}>
					<Tab key={`tab-overview-${locale}`} label="" className={css.tab}>
						<main>
							<div className={css.infoBoxContainer}>
								<div className="infoBoxPrimary">
									{T(C, status)}
									<br />
									<br />
									<Link
										className={css.link}
										title={T(C, `${status}.title`)}
										href={T(C, `${status}.href`)}
										data-gtm-action={`${C}:StickerInactive`}
									/>
								</div>
							</div>
						</main>
					</Tab>
				</Tabs>
			</TabsContainer>
		);
	}
}

export default withIntl(PortalInactive);
