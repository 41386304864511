import React from "react";

import { withIntlCookieInfo } from "components/hocs";
import DefaultPortal from "components/templates/portal/DefaultPortal";

import "../../theme-hdi.scss";

const C = "profiles.hdi-at.portals.default";

const Portal = ({ T, languages, profileId }) => (
	<DefaultPortal
		profileId={profileId}
		length={9}
		options={{ blocks: [3, 3, 3], delimiter: " ", uppercase: true }}
		logoPath="profiles/hdi-at/img/hdi-logo.svg"
		logoWidth={170}
		helpImagePath="profiles/hdi-at/img/help.svg"
		title={T(C, "title")}
		textCamera={T(C, "textCamera")}
		textNoCamera={T(C, "textNoCamera")}
		Menu={<div />}
		theme="theme-hdi"
	/>
);

export default withIntlCookieInfo(Portal, { C: "CookieInfo.Portal", profileId: true });
