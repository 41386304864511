export default {
	textColor: "#141412", // Rangoon Green
	hintTextColor: "rgba(0, 0, 0, 0.60)",
	disabledTextColor: "#9D9D9D",
	disabledColor: "#ccc",
	tertiaryColor: "var(--hdi-green)", // Cardinal
	tertiaryTextColor: "#8b9196", // Oslo Gray
	primaryTextColor: "white",
	secondaryTextColor: "white",
	primaryBackgroundColor: "#f3f1ed", // Pampas
	secondaryBackgroundColor: "#e8e9ea", // Iron
	neutralBackgroundColor: "white",
	alertTextColor: "#c31622", // Cardinal

	/* react-md */
	primaryColor: "#65A518", // HDI Green
	secondaryColor: "#65A518", // HDI Green
	gray1: "#EDEDED", // light gray
	gray2: "#C6C6C6", // light-medium gray
	gray3: "#9D9D9D", // medium gray
	gray4: "#6E6E6E", // dark gray
	gray5: "#404040", // darker gray
	black: "#000", // black
};

// HDI colors
// hellgrün: #ace962
// dunkelgrün: #009c33
// rot: #e30614
