import React, { Component } from "react";
import { Tab, Tabs, TabsContainer } from "lib/react-md";

import { SensotixLink } from "components/utils";
import { LayoutContainer } from "components/containers";
import { withIntl } from "components/hocs";
import TabWebCode from "./TabWebCode";
import TabQRReader from "./TabQRReader";

import css from "./DefaultPortal.module.scss";

const C = "profiles.default.portals.default";

class DefaultPortal extends Component {
	static defaultProps = {
		logoPath: "img/logo.svg",
		logoWidth: 160,
		helpImagePath: "img/help.png",
		length: 9,
		options: { blocks: [3, 3, 3], delimiter: " ", uppercase: true },
		title: "",
		textCamera: "",
		textNoCamera: "",
		Menu: null,
	};

	state = {
		activeTabIndex: 0,
	};

	changeTabIndex = (activeTabIndex) => this.setState({ activeTabIndex });

	render() {
		const {
			T,
			profileId,
			logoPath,
			logoWidth,
			helpImagePath,
			length,
			options,
			title,
			textCamera,
			textNoCamera,
			Menu,
			theme,
		} = this.props;
		const { activeTabIndex } = this.state;
		const cameraAvailable = !!navigator.mediaDevices;

		return (
			<LayoutContainer theme={theme}>
				<div className={css.page}>
					<div className={css.body} style={{ background: "var(--secondary-color)" }}>
						<div className={css.top}>
							<div className={css.header}>
								<div className={css.menu}>
									{Menu}
									<img src={require(`../../../${logoPath}`)} width={logoWidth} alt="Logo" />
								</div>
								<h3>{title}</h3>
								{cameraAvailable && <p>{textCamera}</p>}
								{!cameraAvailable && <p>{textNoCamera}</p>}
							</div>
						</div>
						<TabsContainer
							className={css.tabsContainer}
							activeTabIndex={activeTabIndex}
							onTabChange={this.changeTabIndex}
							swipeableViewsProps={{
								disabled: true,
								springConfig: { duration: "0s", easeFunction: "...", delay: "0s" },
							}}
							colored
						>
							<Tabs tabId="token">
								<Tab label="Abruf" className={css.tab}>
									<TabWebCode
										encodePrefix={profileId}
										length={length}
										options={options}
										placeholder={T(C, "webcode.placeholder", { length })}
										helpImagePath={helpImagePath}
										onNextTab={() => this.changeTabIndex(1)}
										showCameraButton={cameraAvailable}
									/>
								</Tab>
								<Tab label="Foto" className={css.tab}>
									{activeTabIndex === 1 && (
										<TabQRReader
											cameraInfoText={T(C, "qrcode.cameraInfo")}
											onNextTab={() => this.changeTabIndex(0)}
										/>
									)}
								</Tab>
							</Tabs>
						</TabsContainer>
						<div className={css.footer}>
							© {new Date().getFullYear()}{" "}
							<SensotixLink title="Sensotix GmbH" data-gtm-action={`${C}:SensotixGmbH`} /> ・{" "}
							<SensotixLink title="www.sensotix.com" data-gtm-action={`${C}:www.sensotix.com`} />
						</div>
					</div>
				</div>
			</LayoutContainer>
		);
	}
}

export default withIntl(DefaultPortal);
