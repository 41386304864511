import React from 'react'
import styled from 'styled-components/macro'

import IconStepper, {StepWrapper} from 'components/controls/wizard/Steppers/IconStepper'

const ThemedIconStepWrapper = styled(StepWrapper).attrs(({theme}) => ({
	activeTextColor: theme.primaryColor,
	activeBgColor: 'white',
	visitedTextColor: theme.primaryColor,
	visitedBgColor: theme.primaryColor,
	inactiveTextColor: theme.disabledTextColor,
	inactiveBgColor: theme.disabledColor
}))`
	color: ${({active, visited, activeTextColor, visitedTextColor, inactiveTextColor}) => 
	active ? activeTextColor : visited ? visitedTextColor : inactiveTextColor}; 
	
	background-color: ${({active, visited, activeBgColor, inactiveBgColor}) =>
	active ? activeBgColor : inactiveBgColor};
	
	border-right: 1px solid ${({visitedBgColor}) => visitedBgColor};
	
	height: 50px;
	
	@media (max-height: 400px) {
		display: none;
	}
`;

export default props => <IconStepper disableOnClick StepWrapperComponent={ThemedIconStepWrapper} {...props}/>;