import _ from "lib/lodash";

const initialState = {
	/* Component */
	component: "",
	data: "",
	/* Form */
	step: 0,
	substep: 0,
	showFeedback: false,
	/* PageMain */
	owner: "",
	polno: "",
	/* PageClaim */
	id: "",
	claimNo: 0,
	claimId: "",
	/* PageDriver */
	driverPlate: "",
	driverName: "",
	driverTelno: "",
	driverEmail: "",
	driverLicenseAttached: "",
	driverLicenseImage: "",
	driverLicenseId: "",
	driverLicenseIssuer: "",
	driverLicenseClasses: "",
	/* PageCategory */
	category: "",
	subcategory: "",
	/* PageDatetimeLocation */
	datetime: "",
	location: "",
	/* PageParty */
	partyAvailable: "",
	partyKnown: "",
	partyPlate: "",
	partyName: "",
	partyAddress: "",
	partyTelno: "",
	partyEmail: "",
	partyInsurer: "",
	partyHurt: "",
	partyCause: "",
	partyReason: "",
	/* PageComments */
	hasOwnDamages: "",
	damages: "",
	damagesExtended: "",
	details: "",
	comments: "",
	/* Feedback */
	feedback: {},
	/* Text fields */
	categoryText: "",
	subcategoryText: "",
	partyKnownText: "",
	partyHurtText: "",
	partyCauseText: "",
	damagesText: "",
	detailsText: "",
	/* Kasko */
	hasKaskoCoverage: false,
	kaskoCoverageText: "",
	isKaskoDamage: "",
	kaskoDamageText: "",
	isHPDamage: "",
	HPDamageText: "",
};

const reducer = (state, { type, ...otherProps }) => {
	const {
		/* Component */
		component,
		data,
		/* PageMain */
		owner,
		polno,
		/* PageClaim */
		id,
		claimNo,
		claimId,
		/* PageDriver */
		driverPlate,
		driverName,
		driverTelno,
		driverEmail,
		driverLicenseAttached,
		driverLicenseImage,
		driverLicenseId,
		driverLicenseIssuer,
		driverLicenseClasses,
		/* PageCategory */
		category,
		subcategory,
		/* PageDatetimeLocation */
		datetime,
		location,
		/* PageParty */
		partyKnown,
		partyPlate,
		partyName,
		partyAddress,
		partyTelno,
		partyEmail,
		partyInsurer,
		partyHurt,
		partyCause,
		partyReason,
		/* PageComments */
		hasOwnDamages,
		damages,
		damagesExtended,
		details,
		comments,
		/* Feedback */
		feedback,
		/* Kasko */
	} = otherProps;

	let newState = {};

	switch (type) {
		case "FORM_INIT_CLAIM":
			newState = { ...state, component, data, id, claimNo, claimId };
			break;

		/* pages */

		case "FORM_SUBMIT_STEP_MAIN":
			newState = { ...state, owner, polno, step: 1 };
			break;

		case "FORM_SUBMIT_STEP_DRIVER":
			newState = {
				...state,
				driverPlate,
				driverName,
				driverTelno,
				driverEmail,
				driverLicenseAttached,
				driverLicenseImage,
				driverLicenseId,
				driverLicenseIssuer,
				driverLicenseClasses,
				hasKaskoCoverage: true,
				step: 2,
				substep: 0,
			};
			break;

		case "FORM_SUBMIT_STEP_CATEGORY":
			newState = { ...state, category, subcategory, step: 3 };
			break;

		case "FORM_GOTO_PREVIOUS_STEP":
			newState = { ...state, step: Math.max(state.step - 1, 0) };
			break;

		/* wizard substeps */

		/* driver */
		case "FORM_SUBMIT_SUBSTEP_DRIVER_INFO":
			newState = {
				...state,
				driverName,
				driverPlate,
				driverTelno,
				driverEmail,
				substep: 1,
			};
			break;

		/* common */
		case "FORM_SUBMIT_SUBSTEP_COMMON_DATETIME_LOCATION":
			newState = { ...state, datetime, location, substep: 1 };
			break;
		case "FORM_SUBMIT_SUBSTEP_COMMON_OVERVIEW":
			newState = { ...state, step: 0, substep: 0, showFeedback: true };
			break;

		/* collision:car */
		case "FORM_SUBMIT_SUBSTEP_COLLISION_CAR_PARTY":
			newState = {
				...state,
				partyKnown,
				partyPlate,
				partyName,
				partyAddress,
				partyTelno,
				partyEmail,
				partyInsurer,
				substep: 2,
			};
			break;
		case "FORM_SUBMIT_SUBSTEP_COLLISION_CAR_PICTURES":
			newState = { ...state, substep: 3 };
			break;
		case "FORM_SUBMIT_SUBSTEP_COLLISION_CAR_COMMENTS":
			newState = {
				...state,
				partyCause,
				partyReason,
				hasOwnDamages,
				damages,
				comments,
				substep: 4,
			};
			break;

		/* collision:person */
		case "FORM_SUBMIT_SUBSTEP_COLLISION_PERSON_INFO":
			newState = { ...state, substep: 2 };
			break;
		case "FORM_SUBMIT_SUBSTEP_COLLISION_PERSON_PARTY":
			newState = {
				...state,
				partyHurt,
				partyName,
				partyAddress,
				partyTelno,
				partyEmail,
				substep: 3,
			};
			break;
		case "FORM_SUBMIT_SUBSTEP_COLLISION_PERSON_PICTURES":
			newState = { ...state, substep: 4 };
			break;
		case "FORM_SUBMIT_SUBSTEP_COLLISION_PERSON_COMMENTS":
			newState = { ...state, hasOwnDamages, damages, comments, substep: 5 };
			break;

		/* collision:animal */
		case "FORM_SUBMIT_SUBSTEP_COLLISION_ANIMAL_INFO":
			newState = { ...state, substep: 2 };
			break;
		case "FORM_SUBMIT_SUBSTEP_COLLISION_ANIMAL_PICTURES":
			newState = { ...state, substep: 3 };
			break;
		case "FORM_SUBMIT_SUBSTEP_COLLISION_ANIMAL_COMMENTS":
			newState = { ...state, hasOwnDamages, damages, comments, substep: 4 };
			break;

		/* collision:object */
		case "FORM_SUBMIT_SUBSTEP_COLLISION_OBJECT_PARTY":
			newState = {
				...state,
				partyKnown,
				partyCause,
				partyReason,
				partyName,
				partyAddress,
				partyTelno,
				partyEmail,
				substep: 2,
			};
			break;
		case "FORM_SUBMIT_SUBSTEP_COLLISION_OBJECT_PICTURES":
			newState = { ...state, substep: 3 };
			break;
		case "FORM_SUBMIT_SUBSTEP_COLLISION_OBJECT_COMMENTS":
			newState = { ...state, hasOwnDamages, damages, comments, substep: 4 };
			break;

		/* glass */
		case "FORM_SUBMIT_SUBSTEP_GLASS_DETAILS":
			newState = { ...state, damages, details, damagesExtended, substep: 2 };
			break;
		case "FORM_SUBMIT_SUBSTEP_GLASS_PICTURES":
			newState = { ...state, substep: 3 };
			break;
		case "FORM_SUBMIT_SUBSTEP_GLASS_COMMENTS":
			newState = { ...state, comments, substep: 4 };
			break;

		/* parking */
		case "FORM_SUBMIT_SUBSTEP_PARKING_PARTY":
			newState = {
				...state,
				partyKnown,
				partyCause,
				partyReason,
				partyName,
				partyAddress,
				partyTelno,
				partyEmail,
				substep: 2,
			};
			break;
		case "FORM_SUBMIT_SUBSTEP_PARKING_PICTURES":
			newState = { ...state, substep: 3 };
			break;
		case "FORM_SUBMIT_SUBSTEP_PARKING_COMMENTS":
			newState = { ...state, damages, comments, substep: 4 };
			break;

		/* event */
		case "FORM_SUBMIT_SUBSTEP_EVENT_DETAILS":
			newState = { ...state, partyCause, partyReason, substep: 2 };
			break;
		case "FORM_SUBMIT_SUBSTEP_EVENT_PICTURES":
			newState = { ...state, substep: 3 };
			break;
		case "FORM_SUBMIT_SUBSTEP_EVENT_COMMENTS":
			newState = { ...state, comments, substep: 4 };
			break;

		/* other */
		case "FORM_SUBMIT_SUBSTEP_OTHER_PICTURES":
			newState = { ...state, substep: 2 };
			break;
		case "FORM_SUBMIT_SUBSTEP_OTHER_COMMENTS":
			newState = { ...state, hasOwnDamages, damages, comments, substep: 3 };
			break;

		case "FORM_GOTO_PREVIOUS_SUBSTEP":
			newState = { ...state, substep: Math.max(state.substep - 1, 0) };
			break;

		case "FORM_CHANGE_VALUES":
			newState = { ...state, ...otherProps };
			break;

		case "FORM_SUBMIT_FEEDBACK":
			newState = { ...state, feedback, showFeedback: false };
			break;

		case "RESET_FORM":
			newState = { ...initialState, claimId: state.claimId, showFeedback: state.showFeedback }; // keep feedback request
			break;

		default:
			return state;
	}

	return { ...newState, ...getTextFields(newState) };
};

export { reducer, initialState };

const getTextFields = ({
	category,
	subcategory,
	partyKnown,
	partyPlate,
	partyName,
	partyHurt,
	partyCause,
	partyReason,
	damages,
	damagesExtended,
	hasOwnDamages,
	details,
	hasKaskoCoverage,
	feedback,
}) => {
	const textFields = {};

	switch (category) {
		case "collision":
			textFields.categoryText = "Kollision";
			break;
		case "glass":
			textFields.categoryText = "Glasbruch";
			break;
		case "parking":
			textFields.categoryText = "Parkschaden";
			break;
		case "event":
			textFields.categoryText = "Naturereignis";
			break;
		case "other":
			textFields.categoryText = "Andere Schäden";
			break;
		default:
			textFields.categoryText = "(nicht definiert)";
			break;
	}
	switch (subcategory) {
		case "car":
			textFields.subcategoryText = "mit Fahrzeug";
			break;
		case "person":
			textFields.subcategoryText = "mit Person";
			break;
		case "animal":
			textFields.subcategoryText = "mit Tier";
			break;
		case "object":
			textFields.subcategoryText = "mit Objekt";
			break;
		default:
			textFields.subcategoryText = "";
			break;
	}
	switch (partyKnown) {
		case "unknown":
			textFields.partyKnownText = "Unbekannt";
			break;
		case "manual":
			textFields.partyKnownText = "Bekannt";
			break;
		case "self":
			textFields.partyKnownText = "Ich selbst";
			break;
		default:
			textFields.partyKnownText = "";
			break;
	}
	switch (partyHurt) {
		case "yes":
			textFields.partyHurtText = "Ja";
			break;
		case "no":
			textFields.partyHurtText = "Nein";
			break;
		default:
			textFields.partyHurtText = "";
			break;
	}
	switch (partyCause) {
		case "parking":
			textFields.partyCauseText = "beim Ein- oder Ausparken";
			break;
		case "overtaking":
			textFields.partyCauseText = "beim Überholmanöver";
			break;
		case "giveway":
			textFields.partyCauseText = "wegen Vorfahrtmissachtung";
			break;
		case "hail":
			textFields.partyCauseText = "Hagel";
			break;
		case "storm":
			textFields.partyCauseText = "Sturm";
			break;
		case "avalanche":
			textFields.partyCauseText = "Dachlawine";
			break;
		case "other":
			textFields.partyCauseText = partyReason;
			break;
		default:
			textFields.partyCauseText = "";
			break;
	}

	textFields.detailsText = _.transform(
		(details || "").split(","),
		(res, detail) => {
			switch (detail) {
				case "view":
					res.push("im Sichtfeld");
					break;
				case "small":
					res.push("kleiner als eine Zwei-Euro-Münze");
					break;
				case "large":
					res.push("größer als eine Zwei-Euro-Münze");
					break;
				case "crack":
					res.push("ein Riss");
					break;
				default:
					break;
			}
		},
		[]
	).join(", ");

	if (category === "glass") {
		textFields.damagesText = _.transform(
			(damages || "").split(","),
			(res, damage) => {
				switch (damage) {
					case "front":
						res.push("Frontscheibe");
						break;
					case "rearside":
						res.push("Heck-/Seitenscheibe");
						break;
					case "other":
						res.push(damagesExtended);
						textFields.detailsText = "";
						break;
					default:
						res.push(damage);
						break;
				}
			},
			[]
		).join(", ");
	} else textFields.damagesText = damages;

	textFields.partyAvailable =
		!!partyKnown || !!partyName || !!partyPlate || !!partyHurt ? "yes" : "";

	/* Kasko */
	const bHasOwnDamages = hasOwnDamages === "yes";
	textFields.hasOwnDamagesText = bHasOwnDamages ? "Ja" : "Nein";

	// define Kasko qualification:

	// Handelt es sich um einen Haftpflichtschaden?
	textFields.isHPDamage =
		(category === "collision" && subcategory === "car") ||
		(category === "collision" && subcategory === "person") ||
		(category === "collision" && subcategory === "object") ||
		category === "other"
			? "yes"
			: "no";
	textFields.HPDamageText = textFields.isHPDamage === "yes" ? "Haftpflicht" : "";

	// Handelt es sich (zusätzlich) um einen Kaskoschaden?
	textFields.isKaskoDamage =
		!!hasKaskoCoverage &&
		((category === "collision" && subcategory === "car" && !!bHasOwnDamages) ||
			(category === "collision" && subcategory === "person" && !!bHasOwnDamages) ||
			(category === "collision" && subcategory === "animal") ||
			(category === "collision" && subcategory === "object" && !!bHasOwnDamages) ||
			category === "glass" ||
			category === "parking" ||
			category === "event" ||
			(category === "other" && !!bHasOwnDamages))
			? "yes"
			: "no";
	textFields.kaskoDamageText = textFields.isKaskoDamage === "yes" ? "Kasko" : "";

	/*switch (feedback.rating1) {
		case "10":
			textFields.feedbackTextRating1 = 1;
			break;
		case "20":
			textFields.feedbackTextRating1 = 2;
			break;
		case "30":
			textFields.feedbackTextRating1 = 3;
			break;
		case "40":
			textFields.feedbackTextRating1 = 4;
			break;
		case "50":
			textFields.feedbackTextRating1 = 5;
			break;
	}*/

	return textFields;
};
