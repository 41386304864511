import React from "react";
import { Form, Field } from "formik";

import { FormContainer } from "components/forms";
import { StyledButton, StyledImagesUploader } from "../../../../../../controls";
import { useFormState } from "components/hooks";
import ThemedPageWrapper from "../../ThemedPageWrapper";

const C = "profiles.hdi-at.PageClaim.PageCollision.Car.PagePictures";

const PagePicturesForm =
	({ actions, item, images }) =>
	() =>
		(
			<ThemedPageWrapper>
				<Form>
					<Field
						name="images_damage"
						label="Falls möglich, fotografieren Sie bitte die Schäden am Fahrzeug:"
						actions={actions}
						item={item}
						images={images}
						tag="damage"
						component={StyledImagesUploader}
					/>
					<Field
						name="images_report"
						label="Falls ein Unfallbericht ausgefüllt wurde, fotografieren Sie bitte den Bericht:"
						actions={actions}
						item={item}
						images={images}
						tag="report"
						component={StyledImagesUploader}
					/>
				</Form>
			</ThemedPageWrapper>
		);

const PagePictures = ({ wizardProps: { T, actions }, item, images, ...otherProps }) => {
	const [, dispatch] = useFormState();
	const FormComponent = PagePicturesForm({ actions, item, images });

	const onPrevious = () => dispatch({ type: "FORM_GOTO_PREVIOUS_SUBSTEP" });
	const onSubmit = (values) => {
		dispatch({ type: "FORM_SUBMIT_SUBSTEP_COLLISION_CAR_PICTURES", ...values });
		actions.gtmCustomEvent({ eventAction: `journey:${C}:submit` });
	};

	return (
		<FormContainer
			onSubmit={onSubmit}
			FormComponent={FormComponent}
			actionButtons={({ formProps: { submitForm } }) => [
				<StyledButton styled="secondary" key="cancel" onClick={onPrevious}>
					{T(C, "previous")}
				</StyledButton>,
				<StyledButton styled="primary" key="next" onClick={submitForm}>
					{T(C, "next")}
				</StyledButton>,
			]}
		/>
	);
};

export default PagePictures;
