/* imports */
import filesize from 'filesize'
import _ from 'lib/lodash'
import {MB} from 'lib/constants'

import {gtm} from 'components/events'
import {db} from 'services'

/* action types */
export const types = {
	NODE_POST_NODE_SUCCESS: 'NODE/POST_NODE_SUCCESS',
	NODE_POST_NODE_ERROR: 'NODE/POST_NODE_ERROR',

	NODE_POST_SUBNODE_SUCCESS: 'NODE/POST_SUBNODE_SUCCESS',
	NODE_POST_SUBNODE_ERROR: 'NODE/POST_SUBNODE_ERROR',

	NODE_POST_FILE_UPLOADING: 'NODE/POST_FILE_UPLOADING',
	NODE_POST_FILE_TICK: 'NODE/POST_FILE_TICK',
	NODE_POST_FILE_SUCCESS: 'NODE/POST_FILE_SUCCESS',
	NODE_POST_FILE_ERROR: 'NODE/POST_FILE_ERROR',

	NODE_CUSTOM_INFO: 'NODE/CUSTOM_INFO',
	NODE_CUSTOM_ERROR: 'NODE/CUSTOM_ERROR',

	NODE_RESET_STATE: 'NODE/RESET_STATE'
};

/* initial state */
const initialState = {
	isUploading: false,
	uploadProgress: {},
	snapshot: null,
	error: null
};

/* reducer */
export default function (state = initialState, action) {
	switch (action.type) {

		case types.NODE_POST_FILE_UPLOADING:
			return {...state, isUploading: true, uploadProgress: {}, error: null};

		case types.NODE_POST_FILE_TICK:
			const {bytesTransferred, totalBytes} = action.snapshot;
			return {
				...state,
				isUploading: true,
				uploadProgress: {
					bytesTransferred, totalBytes, percentage: 100 * bytesTransferred / totalBytes
				}
			};

		case types.NODE_POST_FILE_SUCCESS:
			return {...state, isUploading: false, snapshot: action.snapshot};

		case types.NODE_POST_FILE_ERROR:
			return {...state, isUploading: false, error: action.error};

		case types.NODE_RESET_STATE:
			return initialState;

		default:
			return state;
	}
}


const cleanFilename = file => {
	let fileName = file.name.substr(0, file.name.lastIndexOf('.'));
	if (/^\d{11}__.{36}$/.test(fileName)) { // iOS video filename, e.g. 52822768057__75E7AE11-1F21-4D8E-8E33-ED4E0634A454
		const options = {
			year: "numeric", month: "short",
			day: "numeric", hour: "2-digit", minute: "2-digit"
		};
		return new Date().toLocaleTimeString("de-DE", options);
	}
	return file.name // original name
};

/* action creators */
export const actions = {
	dispatch: obj => dispatch => dispatch(obj),

	getNextNodeOrder: nodes => {
		const items = _.get(nodes, 'items', []);
		// find item with highest order value
		return _.reduce(items, (maxOrder, item) => Math.max(maxOrder, _.get(item, 'order', -1)), -1) + 1;
	},

	postNode: (payload, nodes) => dispatch => (targets, itemFactory) => {
		const newNode = itemFactory({targets, order: actions.getNextNodeOrder(nodes)});

		return db.postNode(payload, newNode).then(
			nodeRef => dispatch({type: types.NODE_POST_NODE_SUCCESS, id: nodeRef.id}),
			error => dispatch({type: types.NODE_POST_NODE_ERROR, error}),
		);
	},

	getNextSubnodeOrder: (node, target, subnodes) => {
		const nodeTarget = _.get(node, `targets[${target}]`);
		const items = _.get(subnodes, `[${nodeTarget}][${node.$id}].items`, []);

		// find item with highest order value
		return _.reduce(items, (maxOrder, item) => Math.max(maxOrder, _.get(item, 'order', -1)), -1) + 1;
	},

	postSubnode: (stickerId, subnodes) => dispatch => (node, target, itemFactory) => {
		const newSubnode = itemFactory({stickerId, order: actions.getNextSubnodeOrder(node, target, subnodes)});

		const targetRef = _.get(node, `$targetRefs[${target}]`);
		return db.postSubnode(targetRef, newSubnode).then(
			subnodeRef => dispatch({type: types.NODE_POST_SUBNODE_SUCCESS, id: subnodeRef.id}),
			error => dispatch({type: types.NODE_POST_SUBNODE_ERROR, error}),
		);
	},

	postSubnodeAndFile: (stickerId, subnodes) => dispatch => (node, target, itemFactory, file, tag, filename) => {
		const maxSize = _.get(node, 'constraints.size', 0) * MB;
		if (!!maxSize && file.size > maxSize) {
			return dispatch({type: types.NODE_POST_FILE_ERROR, error: new Error("FILE_TOO_LARGE_ERROR"), size: filesize(maxSize)});
		}

		const newSubnode = itemFactory({stickerId, order: actions.getNextSubnodeOrder(node, target, subnodes), tag});

		const startTime = new Date().getTime();

		const onTick = snapshot => dispatch({type: types.NODE_POST_FILE_TICK, snapshot});
		const onError = error => dispatch({type: types.NODE_POST_FILE_ERROR, error});
		const onComplete = snapshot => {
			const timeTaken = (new Date().getTime() - startTime) / 1000;
			const uploadSpeed = parseInt(snapshot.bytesTransferred / timeTaken / 1000); // measured in KB/sec

			dispatch({type: types.NODE_POST_FILE_SUCCESS, snapshot});
			dispatch({type: gtm.GTM_EVENT_CUSTOM, eventAction: "media:upload", eventLabel: snapshot.metadata.fullPath, eventValue: uploadSpeed});
		};

		dispatch({type: types.NODE_POST_FILE_UPLOADING});

		const fileName = file.constructor === File ? cleanFilename(file) : filename;
		_.set(newSubnode, 'properties.label', fileName);

		const targetRef = _.get(node, `$targetRefs[${target}]`);
		return db.postSubnodeAndFile(targetRef, newSubnode, file, fileName, stickerId, onTick, onComplete, onError).then(
			subnodeRef => dispatch({type: types.NODE_POST_SUBNODE_SUCCESS, id: subnodeRef.id}),
			error => dispatch({type: types.NODE_POST_FILE_ERROR, error})
		);
	},

	showCustomInfo: info => ({type: types.NODE_CUSTOM_INFO, info}),

	raiseCustomError: error => ({type: types.NODE_CUSTOM_ERROR, error}),

	resetState: () => ({type: types.NODE_RESET_STATE}),

};
