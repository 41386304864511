import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LS, CS } from "lib/constants";
import _ from "lib/lodash";

import { authActions, portalRouteActions } from "components/handlers/actions";
import { UserInfoProvider } from "components/contexts";

class PortalRoute extends Component {
	getMatchParams = () => {
		const { match = {} } = this.props;
		const portalId = _.get(match, "params.portalId", "") || window.location.hostname;

		return { portalId, ..._.get(match, "params", {}) };
	};

	componentDidMount() {
		const { userCS } = this.props;
		const matchParams = this.getMatchParams();

		// loading screen
		this.props.showAuthLoader({
			title: "portal.title",
			main: "portal.main",
			sub: "portal.sub",
			imgPath: "loading/loading-payloads.min.svg",
			value: 10,
		});

		switch (userCS) {
			case CS.VALID:
			case CS.INVALID:
			case CS.RECEIVED:
				const { userClaims, customClaims } = this.props;
				return this.props.checkIfClaimsValid(matchParams, userClaims, customClaims);
			default:
				return;
		}
	}

	componentDidUpdate() {
		const { userCS } = this.props;
		const matchParams = this.getMatchParams();

		switch (userCS) {
			case CS.VALID:
				const { portal, error, componentLS, translationsLS } = this.props;

				if (_.has(portal, "component") && !error) {
					if (componentLS === LS.INIT) this.props.loadPortalComponent(portal.component);
					if (translationsLS === LS.INIT) this.props.loadTranslations(portal.component);
					if (componentLS === LS.LOADED && translationsLS === LS.LOADED)
						this.props.portalRouteLoadingFinished();
				}
				return;

			case CS.RECEIVED:
				const { userClaims, customClaims } = this.props;

				return this.props.checkIfClaimsValid(matchParams, userClaims, customClaims);

			case CS.INVALID:
				return this.props.signIn(matchParams);

			default:
				return;
		}
	}

	componentWillUnmount() {
		this.props.resetAuthHandler(); // required to navigate between portals and stickers
		this.props.resetPortalRouteHandler();
	}

	render() {
		const { error, authError } = this.props;

		// make sure TAG_NOT_FOUND error prevents page from redirecting
		if (!!authError && authError.message === "PORTAL_NOT_FOUND") {
			return <Redirect to={{ pathname: "/404", state: { ...authError } }} />;
		}
		if (!!error) {
			return <Redirect to={{ pathname: "/error", state: { ...error } }} />;
		}

		const { profileId, languages, portal, PortalComponent, componentLS, translationsLS } =
			this.props;

		return (
			componentLS === LS.LOADED &&
			translationsLS === LS.LOADED && (
				<UserInfoProvider profileId={profileId}>
					<PortalComponent profileId={profileId} languages={languages} portal={portal} />
				</UserInfoProvider>
			)
		);
	}
}

const mapStateToProps = ({ authHandler, portalRouteHandler }) => ({
	...authHandler,
	...portalRouteHandler,
});

export default connect(mapStateToProps, { ...portalRouteActions, ...authActions })(PortalRoute);
