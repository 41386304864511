import React from "react";
import { Form, Field } from "formik";
import * as Yup from "yup";

import { FormContainer } from "components/forms";
import { StyledButton, StyledFormTextField, StyledFormRadioGroup } from "../../../../../controls";
import { useFormState } from "components/hooks";
import ThemedPageWrapper from "../ThemedPageWrapper";

const C = "profiles.hdi-at.PageClaim.PageOther.PageComments";

const PageCommentsForm =
	({ onChanged }) =>
	({ formProps: { values } }) => {
		const controlsHasOwnDamages = [
			{ label: "Ja", value: "yes" },
			{ label: "Nein", value: "no" },
		];

		return (
			<ThemedPageWrapper>
				<Form>
					<Field
						type="text"
						name="hasOwnDamages"
						label="Wurde Ihr Fahrzeug beschädigt?"
						component={StyledFormRadioGroup}
						controls={controlsHasOwnDamages}
						onChanged={(value) =>
							onChanged({ ...values, ...value, damages: value === "yes" ? values.damages : "" })
						}
					/>
					{values.hasOwnDamages === "yes" && (
						<Field
							type="text"
							name="damages"
							label="Schäden am eigenen Fahrzeug"
							component={StyledFormTextField}
							rows={3}
						/>
					)}
					<Field
						type="text"
						name="comments"
						label="Bitte beschreiben Sie kurz den Schadenhergang:"
						placeholder="Bitte ausfüllen..."
						component={StyledFormTextField}
						rows={5}
					/>
				</Form>
			</ThemedPageWrapper>
		);
	};

const validationSchema = Yup.object().shape({
	hasOwnDamages: Yup.string().required("Bitte geben Sie an, ob Ihr Fahrzeug beschädigt wurde."),
	damages: Yup.string().when("hasOwnDamages", {
		is: "yes",
		then: Yup.string().required("Bitte zählen Sie die Schäden am eigenen Fahrzeug auf."),
	}),
	comments: Yup.string().required("Bitte geben Sie Details zum Schadenhergang an."),
});

const PageComments = ({ wizardProps: { T, actions }, ...otherProps }) => {
	const [formState, dispatch] = useFormState();
	const { hasOwnDamages, damages, comments } = formState;
	const initialValues = { hasOwnDamages, damages, comments };

	const onChanged = (values) => dispatch({ type: "FORM_CHANGE_VALUES", ...values });
	const FormComponent = PageCommentsForm({ onChanged });

	const onPrevious = () => dispatch({ type: "FORM_GOTO_PREVIOUS_SUBSTEP" });
	const onSubmit = (values) => {
		dispatch({ type: "FORM_SUBMIT_SUBSTEP_OTHER_COMMENTS", ...values });
		actions.gtmCustomEvent({ eventAction: `journey:${C}:submit` });
	};

	return (
		<FormContainer
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			FormComponent={FormComponent}
			actionButtons={({ formProps: { submitForm } }) => [
				<StyledButton styled="secondary" key="cancel" onClick={onPrevious}>
					{T(C, "previous")}
				</StyledButton>,
				<StyledButton styled="primary" key="next" onClick={submitForm}>
					{T(C, "next")}
				</StyledButton>,
			]}
		/>
	);
};

export default PageComments;
