import React, {useState, useEffect} from 'react'
import AddItemDropzone from './AddItemsDropzone'
import {ImageFactory} from 'components/nodes/factories'
import FormConfirmButton from './FormConfirmButton'
import {FontIcon} from 'lib/react-md'
import _ from 'lib/lodash'
import styled from 'styled-components/macro'
import useImage from './useImage'
import LoadingDots from './LoadingDots'
import Lightbox from 'react-image-lightbox';
import {DataConsumer} from 'components/contexts'

import 'react-image-lightbox/style.css';

const ErrorIcon = ({className}) => <div className={className}><FontIcon>warning</FontIcon></div>;
const StyledErrorIcon = styled(ErrorIcon)`
  display: flex;
  justify-content: center;
  align-items: center;
	width: 100%;
  height: 100%;
	background: ${({theme}) => theme.secondaryBackgroundColor};
  border-radius: 8px;
  
  i {
		font-size: 36px;
		color: ${({theme}) => theme.alertTextColor};
	}
`;

const StyledLoadingDots = styled(LoadingDots)`
  height: 100%;
	background: lightgrey;
  border-radius: 8px;
    
	div {
		background-color: ${({theme}) => theme.secondaryColor};
	}
`;

const OuterWrapper = styled.div`
	width: 100%;
	height: ${({height}) => height};
`;

const InnerWrapper = styled.div`
 	display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
 	grid-auto-rows: ${({height}) => height};
 	grid-gap: 8px;
 	
 	@media (min-width: 401px) and (max-width: 600px) {
 		grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
 	}
 	
 	@media (min-width: 320px) and (max-width: 400px) {
 		grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
 	}
 	
 	@media (min-width: 100px) and (max-width: 319px) {
 		grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
 	}
`;

const ImageWrapper = styled.div`
	position: relative;
	height: 100%;
  text-align: center;

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
		border-radius: 8px;
	}
`;

const DropzoneWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	
	background: ${({theme}) => theme.secondaryColor};
	border-radius: 8px;
		
	div {
		width: 100%;
		text-align: center;
		outline: none;
	}
	
	i {
		font-size: 60px;
		color: ${({theme}) => theme.secondaryTextColor};
	}
`;

const Tile = ({item: {id, thumbnail, alt, image}, onClick, onUploadStatusUpdate}) => {
  const [, status] = useImage(id, thumbnail, _.get(image, 'storage.status'));

  useEffect(() => onUploadStatusUpdate({id, status}), [id, status, onUploadStatusUpdate]);

  return (
    <ImageWrapper key={id}>
      {status === "loading" && <StyledLoadingDots/>}
      {status === "failed" && <StyledErrorIcon/>}
      {status === "loaded" && <img src={thumbnail} alt={alt} onClick={onClick}/>}
    </ImageWrapper>
  );
};

const ImagesLightbox = ({mainItem, nextItem, prevItem, mainActions, onClose, onMoveNext, onMovePrev}) => (
  <Lightbox
    discourageDownloads={false}
    mainSrc={mainItem && mainItem.original}
    mainSrcThumbnail={mainItem && mainItem.thumbnail}
    nextSrc={nextItem && nextItem.original}
    nextSrcThumbnail={nextItem && nextItem.thumbnail}
    prevSrc={prevItem && prevItem.original}
    prevSrcThumbnail={prevItem && prevItem.thumbnail}
    toolbarButtons={mainActions}
    onMoveNextRequest={onMoveNext}
    onMovePrevRequest={onMovePrev}
    onCloseRequest={onClose}
  />
);

const LightboxIcon = styled(FormConfirmButton).attrs({
  className: "ril__toolbarItemChild ril__builtinButton",
})`
	&& {
		width: unset;
		height: unset;
	}
	button {
		color: inherit;
	}
`;

const imageSorter = (a, b) => {
  let diff = 0;
  try {
    diff = a.created.toDate() - b.created.toDate();
  } catch (e) {
  }
  return diff;
};

const ImagesUploader = ({actions, item, images, tag, outerHeight = "auto", innerHeight = "80px"}) => {
  const [items, setItems] = useState([]);
  const [mainIndex, setMainIndex] = useState(0);
  const [showLightbox, setShowLightbox] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setItems(_.map(images, image => ({
      image,
      created: image.created,
      id: image.$id,
      alt: image.$id,
      original: _.get(image, 'storage.fileUrl'),
      description: _.get(image, 'properties.label'),
      thumbnail: _.get(image, 'storage.thumbUrl'),
      width: _.get(image, 'storage.metadata.width'),
      height: _.get(image, 'storage.metadata.height'),
      tag: _.get(image, 'properties.tag', '')
    })).filter(image => !!tag ? image.tag === tag : true).sort(imageSorter))
  }, [tag, images, setItems]);

  const onDrop = acceptedFiles => {
    _.forEach(acceptedFiles, file => actions.postSubnodeAndFile(item, "slave", ImageFactory, file, tag));
  };

  const accept = ["image/*", "capture=camera"];
  const nextIndex = (mainIndex + 1) % items.length;
  const prevIndex = Math.max(0, (mainIndex + items.length - 1) % items.length);

  const onClickImage = idx => () => {
    setMainIndex(idx);
    setShowLightbox(true);
  };
  const onDeleteImage = () => {
    actions.deleteDocument(items[mainIndex].image);
    setMainIndex(prevIndex);
  };

  const onMoveNext = () => setMainIndex(nextIndex);
  const onMovePrev = () => setMainIndex(prevIndex);
  const onClose = () => setShowLightbox(false);
  /*const onRotateLeft = () => {
    // not working yet
    const img = document.querySelector('img.ril-image-current');
    img.setAttribute('style','transform:rotate(90deg)');
  };*/

  const mainActions = [
    <LightboxIcon icon="delete" confirmIcon="delete" cancelIcon="" action={onDeleteImage}/>,
    <div style={{width: "24px"}}/>
  ];

  const renderLightbox = () => (
    <ImagesLightbox
      mainItem={items[mainIndex]}
      nextItem={items.length > 1 ? items[nextIndex] : undefined}
      prevItem={items.length > 1 ? items[prevIndex] : undefined}
      mainActions={mainActions}
      onMoveNext={onMoveNext}
      onMovePrev={onMovePrev}
      onClose={onClose}
    />
  );

  const loadingStates = {};

  const onUploadStatusUpdate = ({id, status}) => {
    loadingStates[id] = status;
    setIsLoading(Array.from(Object.values(loadingStates)).includes("loading"));
  };

  return (
    <DataConsumer>
      {({isUploading}) => (
        <OuterWrapper height={outerHeight}>
          <InnerWrapper height={innerHeight}>
            {!isUploading && (!isLoading || !_.size(items)) && (
              <DropzoneWrapper>
                <AddItemDropzone accept={accept} onDrop={onDrop}/>
              </DropzoneWrapper>
            )}
            {items.map((item, idx) => <Tile key={item.id} item={item} onClick={onClickImage(idx)} onUploadStatusUpdate={onUploadStatusUpdate}/>)}
            {showLightbox && renderLightbox()}
          </InnerWrapper>
        </OuterWrapper>
      )}
    </DataConsumer>
  );
};

export default ImagesUploader;