/* imports */
import { hash } from "lib/utils";

const C = "scannerHandler";

/* action types */
export const types = {
	WEBCODE_SCAN_SUCCESS: "WEBCODE_SCANNER/SCAN_SUCCESS",

	QR_IS_READY: "QR_SCANNER/IS_READY",
	QR_SCAN_SUCCESS: "QR_SCANNER/SCAN_SUCCESS",
	QR_SCAN_ERROR: "QR_SCANNER/SCAN_ERROR",

	SCANNER_RESET_STATE: "SCANNER/RESET_STATE",
};

/* initial state */
const initialState = {
	data: null,
	error: null,
	url: "",
};

/* reducer */
export default (state = initialState, action) => {
	switch (action.type) {
		case types.WEBCODE_SCAN_SUCCESS:
			return { ...state, data: action.data, error: null, url: action.url };

		case types.QR_IS_READY:
			return initialState;

		case types.QR_SCAN_SUCCESS:
			return { ...state, data: action.data, error: null, url: action.url };

		case types.QR_SCAN_ERROR:
			return { ...state, data: action.data, error: action.error, url: "" };

		case types.SCANNER_RESET_STATE:
			return initialState;

		default:
			return state;
	}
};

/* action creators */
export const actions = {
	handleWebCodeData:
		(encodePrefix = "", data = "") =>
		(dispatch) => {
			//create hash-hash of profileId & data and use the first 24 characters as tagId
			let tagId = hash(`${encodePrefix}-${data}`).substr(0, 24);

			if (encodePrefix === "helvetia-ch" && data === "ABXTRWQZS") {
				tagId = "helvetia-smartsticker-portal";
			}

			const url = `${window.location.pathname.replace(/\/$/, "")}/${tagId}`; // remove trailing slash first, if available
			dispatch({ type: types.WEBCODE_SCAN_SUCCESS, data, url });
		},

	handleQRLoad: () => ({ type: types.QR_IS_READY }),

	handleQRData: (data) => (dispatch) => {
		/* check for correct QR content */
		try {
			let url = new URL(data);

			if (url.hostname === "www.helvetia.ch" && url.pathname === "/smartsticker") {
				return (window.location = window.location.href + "helvetia-smartsticker-portal");
			}

			if (url.origin.indexOf("stx.world") === -1) {
				throw new Error();
			}

			dispatch({ type: types.QR_SCAN_SUCCESS, data, url: url.pathname + url.search });
		} catch (error) {
			dispatch({
				type: types.QR_SCAN_ERROR,
				data,
				error: { code: "QR_SCANNER_ERROR", message: `${C}.invalidQrCode` },
			});
		}
	},

	handleQRError: (error) => {
		const e = new Error();
		e.code = error.name;

		switch (error.name) {
			case "NotAllowedError":
				e.message = `${C}.NotAllowedError`;
				break;
			case "NoVideoInputDevicesError":
				e.message = `${C}.NoVideoInputDevicesError`;
				break;
			default:
				break;
		}

		return { type: types.QR_SCAN_ERROR, error: e };
	},

	resetScannerHandler: () => ({ type: types.SCANNER_RESET_STATE }),
};
