import React, {useState, useEffect} from 'react'
import styled from 'styled-components/macro'
import Lightbox from 'react-image-lightbox';
import imageCompression from 'browser-image-compression';
import {FontIcon} from 'lib/react-md'
import _ from 'lib/lodash'

import AddItemDropzone from './AddItemsDropzone'
import FormConfirmButton from './FormConfirmButton'
import LoadingDots from './LoadingDots'

import 'react-image-lightbox/style.css';

const ErrorIcon = ({className}) => <div className={className}><FontIcon>warning</FontIcon></div>;
const StyledErrorIcon = styled(ErrorIcon)`
  display: flex;
  justify-content: center;
  align-items: center;
	width: 100%;
  height: 100%;
	background: ${({theme}) => theme.secondaryBackgroundColor};
  border-radius: 8px;
  
  i {
		font-size: 36px;
		color: ${({theme}) => theme.alertTextColor};
	}
`;

const StyledLoadingDots = styled(LoadingDots)`
  height: 100%;
	background: lightgrey;
  border-radius: 8px;
    
	div {
		background-color: ${({theme}) => theme.secondaryColor};
	}
`;

const OuterWrapper = styled.div`
	width: 100%;
	height: ${({height}) => height};
`;

const InnerWrapper = styled.div`
 	display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
 	grid-auto-rows: ${({height}) => height};
 	grid-gap: 8px;
 	
 	@media (min-width: 401px) and (max-width: 600px) {
 		grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
 	}
 	
 	@media (min-width: 320px) and (max-width: 400px) {
 		grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
 	}
 	
 	@media (min-width: 100px) and (max-width: 319px) {
 		grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
 	}
`;

const ImageWrapper = styled.div`
	position: relative;
	height: 100%;
  text-align: center;
  
  background: ${({theme}) => theme.primaryBackgroundColor};
  border: 2px solid ${({theme}) => theme.secondaryColor};
	border-radius: 10px;

	img {
		object-fit: scale-down;
		width: 100%;
		height: 100%;
		border-radius: 8px;
	}
`;

const DropzoneWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	
	background: ${({theme}) => theme.secondaryColor};
	border-radius: 8px;
		
	div {
		width: 100%;
		text-align: center;
		outline: none;
	}
	
	i {
		font-size: 60px;
		color: ${({theme}) => theme.secondaryTextColor};
	}
`;

const ImagesLightbox = ({mainItem, nextItem, prevItem, mainActions, onClose, onMoveNext, onMovePrev}) => (
  <Lightbox
    discourageDownloads={false}
    mainSrc={mainItem && mainItem.original}
    mainSrcThumbnail={mainItem && mainItem.thumbnail}
    nextSrc={nextItem && nextItem.original}
    nextSrcThumbnail={nextItem && nextItem.thumbnail}
    prevSrc={prevItem && prevItem.original}
    prevSrcThumbnail={prevItem && prevItem.thumbnail}
    toolbarButtons={mainActions}
    onMoveNextRequest={onMoveNext}
    onMovePrevRequest={onMovePrev}
    onCloseRequest={onClose}
  />
);

const LightboxIcon = styled(FormConfirmButton).attrs({
  className: "ril__toolbarItemChild ril__builtinButton",
})`
	&& {
		width: unset;
		height: unset;
	}
	button {
		color: inherit;
	}
`;

const LocalImageUploader = ({
                              options,
                              value,
                              onAdd = _.noop,
                              onLoaded = _.noop,
                              onDelete = _.noop,
                              outerHeight = "auto",
                              innerHeight = "160px"
                            }) => {
  const [status, setStatus] = useState("");
  const [items, setItems] = useState({
    originalFile: undefined,
    compressedImage: undefined
  });
  const [showLightbox, setShowLightbox] = useState(false);

  useEffect(() => {
    if (!!value) {
      setItems({
        compressedImage: {
          src: value
        }
      });
      setStatus("loaded");
    }
  }, [value]);


  const compressFile = async originalFile => {
    try {
      const compressedFile = await imageCompression(originalFile, options);
      return {
        originalFile,
        compressedFile: compressedFile.size < originalFile.size ? compressedFile : originalFile
      };
    } catch (error) {
      console.error(error);
      setStatus("failed");
      return {
        originalFile,
        compressedFile: originalFile
      };
    }
  };

  const onDrop = async acceptedFiles => {
    if (acceptedFiles.length > 0) {
      onAdd(); // calling onAdd handler for persistence etc.
      setStatus("loading");

      const items = await compressFile(acceptedFiles[0]);
      if (!items.compressedFile) {
        setStatus("failed");
        return;
      }

      const reader = new FileReader();
      reader.addEventListener("load", () => {
        if (!!reader.result) {
          const img = new Image();
          img.src = reader.result;//URL.createObjectURL(items.compressedFile);
          img.onload = () => {
            const newItems = {
              originalFile: items.originalFile,
              compressedFile: items.compressedFile,
              compressedImage: {
                src: img.src,
                width: img.naturalWidth,
                height: img.naturalHeight,
                size: items.compressedFile.size,
                base64: reader.result
              }
            };
            setItems(newItems);
            onLoaded(newItems);
          }
        }
      });
      reader.readAsDataURL(items.compressedFile);
    }
  };

  const accept = ["image/*", "capture=camera"];

  const onClickImage = idx => () => {
    setShowLightbox(true);
  };

  const onDeleteImage = () => {
    onClose();

    //URL.revokeObjectURL(items.compressedFile);
    onDelete(items);
    setStatus("");
    setItems({
      originalFile: undefined,
      compressedImage: undefined
    });
  };

  const onClose = () => setShowLightbox(false);

  const mainActions = [
    <LightboxIcon icon="delete" confirmIcon="delete" cancelIcon="" action={onDeleteImage}/>,
    <div style={{width: "24px"}}/>
  ];

  const item = {
    id: "image-compressed",
    src: _.get(items, 'compressedImage.src', null),
    alt: "image-compressed"
  };

  const renderLightbox = () => (
    <ImagesLightbox
      mainItem={{
        original: item.src,
        thumbnail: item.src,
      }}
      mainActions={mainActions}
      onClose={onClose}
    />
  );

  return (
    <OuterWrapper height={outerHeight}>
      <InnerWrapper height={innerHeight}>
        {(status === "" || status === "failed") && (
          <DropzoneWrapper>
            <AddItemDropzone accept={accept} onDrop={onDrop} multiple={false} icon="camera_alt"/>
          </DropzoneWrapper>
        )}
        {!!status && (
          <ImageWrapper>
            {status === "loading" && <StyledLoadingDots/>}
            {status === "failed" && <StyledErrorIcon/>}
            {status === "loaded" && <img src={item.src} alt={item.alt} onClick={onClickImage(0)}/>}
          </ImageWrapper>
        )}
        {showLightbox && renderLightbox()}
      </InnerWrapper>
    </OuterWrapper>
  );
};

/*
		const options = {
			maxSizeMB: Number.POSITIVE_INFINITY,
			maxWidthOrHeight: undefined,
			useWebWorker: true,
			maxIteration: 10,
			fileType: 'image/jpeg',
			onProgress: () => null
		};
 */

export default LocalImageUploader;