import React from "react";

import { Link, SensotixLink } from "components/utils";
import { withIntl } from "components/hocs";

import css from "./StickerFooter.module.scss";

const C = "profiles.hdi-at.StickerFooter";

const StickerFooter = ({ T }) => (
	<div className={css.container}>
		<div className={css.footerTop}>
			<div className={css.footerMain}>
				<strong>{T(C, "title")}</strong>
				<div>{T(C, "subtitle1")}</div>
			</div>
			<div className={css.footerContact}>
				<div>
					{T(C, "subtitle2")} <a href={`tel:${T(C, "tel_href")}`}>{T(C, "tel")}</a>
				</div>
				<div>{T(C, "subtitle3")}</div>
				<div></div>
				<div>{T(C, "subtitle4")}</div>
			</div>
		</div>
		<div className={css.footerBottom}>
			<div className={css.footerLinks}>
				<Link
					title={T(C, "link.imprint.title")}
					href={T(C, "link.imprint.href")}
					data-gtm-action={`${C}:Impressum`}
				/>
				&nbsp;·&nbsp;
				<Link
					title={T(C, "link.legal.title")}
					href={T(C, "link.legal.href")}
					data-gtm-action={`${C}:Rechtliches`}
				/>
				&nbsp;·&nbsp;
				<Link
					title={T(C, "link.privacy.title")}
					href={T(C, "link.privacy.href")}
					data-gtm-action={`${C}:Datenschutz`}
				/>
			</div>
			<p />
			<div className={css.footerLogo}>
				<SensotixLink
					title="Smart Stickers by Sensotix."
					data-gtm-action={`${C}:Smart Stickers by Sensotix`}
				/>
			</div>
		</div>
	</div>
);

export default withIntl(StickerFooter);
