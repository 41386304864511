import React from "react";
import { Form, Field } from "formik";
import * as Yup from "yup";

import { FormContainer } from "components/forms";
import { StyledButton, StyledFormRadioGroup, StyledFormTextField } from "../../../../../controls";
import { useFormState } from "components/hooks";
import ThemedPageWrapper from "../ThemedPageWrapper";

const C = "profiles.hdi-at.PageClaim.PageEvent.PageDetails";

const PageDetailsForm =
	() =>
	({ formProps: { values } }) => {
		const controlsPartyCause = [
			{ label: "Hagel", value: "hail" },
			{ label: "Sturm", value: "storm" },
			{ label: "Dachlawine", value: "avalanche" },
			{ label: "Andere Ursache", value: "other" },
		];

		return (
			<ThemedPageWrapper>
				<Form>
					<Field
						type="text"
						name="partyCause"
						label="Schadenursache"
						component={StyledFormRadioGroup}
						controls={controlsPartyCause}
					/>
					{values.partyCause === "other" && (
						<Field
							type="text"
							name="partyReason"
							label="Ursache"
							placeholder="Bitte ausfüllen..."
							component={StyledFormTextField}
						/>
					)}
				</Form>
			</ThemedPageWrapper>
		);
	};

const validationSchema = Yup.object().shape({
	partyCause: Yup.string().required("Bitte geben Sie die Schadenursache an."),
	partyReason: Yup.string().when("partyCause", {
		is: "other",
		then: Yup.string().required("Bitte geben Sie die Ursache an."),
	}),
});

const PageSpecifics = ({ wizardProps: { T, actions }, item, images }) => {
	const [formState, dispatch] = useFormState();
	const { partyCause, partyReason } = formState;
	const initialValues = { partyCause, partyReason };

	const FormComponent = PageDetailsForm();

	const onPrevious = () => dispatch({ type: "FORM_GOTO_PREVIOUS_SUBSTEP" });
	const onSubmit = (values) => {
		dispatch({ type: "FORM_SUBMIT_SUBSTEP_EVENT_DETAILS", ...values });
		actions.gtmCustomEvent({ eventAction: `journey:${C}:submit` });
	};

	return (
		<FormContainer
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			FormComponent={FormComponent}
			actionButtons={({ formProps: { submitForm } }) => [
				<StyledButton styled="secondary" key="cancel" onClick={onPrevious}>
					{T(C, "previous")}
				</StyledButton>,
				<StyledButton styled="primary" key="next" onClick={submitForm}>
					{T(C, "next")}
				</StyledButton>,
			]}
		/>
	);
};

export default PageSpecifics;
