import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LS, CS } from "lib/constants";
import _ from "lib/lodash";

import { authActions, stickerRouteActions } from "components/handlers/actions";
import { UserInfoProvider } from "components/contexts";

class StickerRoute extends Component {
	getMatchParams = () => {
		const { match = {} } = this.props;
		const portalId = _.get(match, "params.portalId", "") || window.location.hostname;

		return { portalId, ..._.get(match, "params", {}) };
	};

	componentDidMount() {
		const { userCS } = this.props;
		const matchParams = this.getMatchParams();

		// loading screen
		this.props.showAuthLoader({
			title: "sticker.title",
			main: "sticker.main",
			sub: "sticker.sub",
			imgPath: "loading/loading-payloads.min.svg",
			value: 10,
		});

		switch (userCS) {
			case CS.VALID:
			case CS.INVALID:
			case CS.RECEIVED:
				const { userClaims, customClaims } = this.props;
				return this.props.checkIfClaimsValid(matchParams, userClaims, customClaims);

			default:
				return;
		}
	}

	componentDidUpdate() {
		const { userCS } = this.props;
		const matchParams = this.getMatchParams();

		switch (userCS) {
			case CS.VALID:
				const { sticker, error, componentLS, translationsLS } = this.props;

				if (_.has(sticker, "component") && !error) {
					if (componentLS === LS.INIT) this.props.loadStickerComponent(sticker.component);
					if (translationsLS === LS.INIT) this.props.loadTranslations(sticker.component);
				}
				return;

			case CS.RECEIVED:
				const { userClaims, customClaims } = this.props;

				return this.props.checkIfClaimsValid(matchParams, userClaims, customClaims);

			case CS.INVALID:
				return this.props.signIn(matchParams);

			default:
				return;
		}
	}

	componentWillUnmount() {
		this.props.resetAuthHandler(); // required to navigate between portals and stickers
		this.props.resetStickerRouteHandler();
	}

	render() {
		const { error, authError } = this.props;

		if (!!error || !!authError) return <Redirect to="./" />;

		const {
			profileId,
			languages,
			portal,
			bundle,
			sticker,
			StickerComponent,
			componentLS,
			translationsLS,
		} = this.props;

		return (
			componentLS === LS.LOADED &&
			translationsLS === LS.LOADED && (
				<UserInfoProvider profileId={profileId}>
					<StickerComponent
						profileId={profileId}
						languages={languages}
						portal={portal}
						bundle={bundle}
						sticker={sticker}
					/>
				</UserInfoProvider>
			)
		);
	}
}

const mapStateToProps = ({ authHandler, stickerRouteHandler }) => ({
	...authHandler,
	...stickerRouteHandler,
});

export default connect(mapStateToProps, { ...stickerRouteActions, ...authActions })(StickerRoute);
