import React from "react";
import { Form, Field } from "formik";
import * as Yup from "yup";
import _ from "lib/lodash";

import { FormContainer } from "components/forms";
import {
	StyledButton,
	StyledFormRadioGroup,
	StyledFormTextField,
	StyledLocalImageUploader,
} from "../../../../controls";
import { useFormState } from "components/hooks";
import ThemedPageWrapper from "../PageClaim/ThemedPageWrapper";

const C = "profiles.hdi-at.PageDriver.PageDriverLicense";

const PageDriverLicenseForm =
	({ onChanged }) =>
	({ formProps: { values } }) => {
		const controls = [
			{ label: "mittels Foto", value: "image" },
			{ label: "manuell eingeben", value: "manual" },
		];

		return (
			<ThemedPageWrapper>
				<Form>
					<Field
						type="text"
						name="driverLicenseAttached"
						label="Führerscheindaten"
						component={StyledFormRadioGroup}
						controls={controls}
						inline
						onChanged={onChanged}
					/>
					{values.driverLicenseAttached === "image" && (
						<Field
							name="driverLicenseImage"
							label="Fotografieren Sie bitte den Führerschein:"
							tag="damage"
							component={StyledLocalImageUploader}
							options={{
								maxSizeMB: 600 / 1024,
								maxWidthOrHeight: 600,
								useWebWorker: true,
								maxIteration: 10,
								fileType: "image/jpeg",
								onProgress: () => null,
							}}
							onLoaded={(items) => onChanged({ driverLicenseImage: items.compressedImage.src })}
							onDelete={() => onChanged({ driverLicenseImage: "" })}
						/>
					)}
					{values.driverLicenseAttached === "manual" && (
						<>
							<Field
								type="text"
								name="driverLicenseId"
								label="Führerscheinnummer"
								placeholder="Bitte ausfüllen..."
								component={StyledFormTextField}
							/>
							<Field
								type="text"
								name="driverLicenseIssuer"
								label="Ausstellende Behörde"
								placeholder="Bitte ausfüllen..."
								component={StyledFormTextField}
							/>
							<Field
								type="text"
								name="driverLicenseClasses"
								label="Fahrzeugklassen"
								placeholder="Bitte ausfüllen..."
								component={StyledFormTextField}
							/>
						</>
					)}
				</Form>
			</ThemedPageWrapper>
		);
	};

const validationSchema = Yup.object().shape(
	{
		driverLicenseImage: Yup.string().when("driverLicenseAttached", {
			is: (driverLicenseAttached) => driverLicenseAttached === "image",
			then: Yup.string()
				.trim()
				.required(
					"Bitte machen Sie ein Foto vom Führerschein oder geben Sie die Daten manuell ein."
				),
			otherwise: Yup.string(),
		}),
		driverLicenseId: Yup.string().when("driverLicenseAttached", {
			is: (driverLicenseAttached) => driverLicenseAttached === "manual",
			then: Yup.string().trim().required("Bitte geben Sie die Führerscheinnummer an."),
			otherwise: Yup.string(),
		}),
		driverLicenseIssuer: Yup.string().when("driverLicenseAttached", {
			is: (driverLicenseAttached) => driverLicenseAttached === "manual",
			then: Yup.string().trim().required("Bitte geben Sie die ausstellende Behörde an."),
			otherwise: Yup.string(),
		}),
		driverLicenseClasses: Yup.string().when("driverLicenseAttached", {
			is: (driverLicenseAttached) => driverLicenseAttached === "manual",
			then: Yup.string().trim().required("Bitte geben Sie die Führerscheinklasse(n) an."),
			otherwise: Yup.string(),
		}),
	},
	["driverLicenseAttached"]
);

const PageDriverLicense = ({
	wizardProps: { T, actions },
	submitForm,
	userInfo,
	setUserInfo,
	...otherProps
}) => {
	const [, dispatch] = useFormState();
	const {
		driverLicenseAttached = "image",
		driverLicenseImage = "",
		driverLicenseId = "",
		driverLicenseIssuer = "",
		driverLicenseClasses = "",
	} = _.get(userInfo, "driver", {});
	const initialValues = {
		driverLicenseAttached: driverLicenseAttached || "image",
		driverLicenseId,
		driverLicenseIssuer,
		driverLicenseClasses,
		driverLicenseImage,
	};

	const onChanged = (values) => setUserInfo(_.merge({}, userInfo, { driver: values }));

	const FormComponent = PageDriverLicenseForm({ onChanged });

	const onPrevious = () => dispatch({ type: "FORM_GOTO_PREVIOUS_SUBSTEP" });

	const onSubmit = async (values, ...submitProps) => {
		const updatedUserInfo = _.merge({}, userInfo, { driver: values });
		setUserInfo(updatedUserInfo);
		actions.gtmCustomEvent({ eventAction: `journey:${C}:submit` });
		submitForm(updatedUserInfo, ...submitProps);
	};

	return (
		<FormContainer
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			FormComponent={FormComponent}
			actionButtons={({ formProps: { submitForm } }) => [
				<StyledButton styled="secondary" key="cancel" onClick={onPrevious}>
					{T(C, "previous")}
				</StyledButton>,
				<StyledButton styled="primary" key="next" onClick={submitForm}>
					{T(C, "next")}
				</StyledButton>,
			]}
		/>
	);
};

export default PageDriverLicense;
