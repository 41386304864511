import React, {PureComponent} from 'react';
import _ from 'lib/lodash'

import {ConfirmationDialog} from 'components/controls'

import css from './ButtonAssistance.module.scss'
import {StyledButton} from '../../../../controls';

const C = "profiles.helvetia-at.PageMain.ButtonAssistance";

class ButtonAssistance extends PureComponent {
	state = {
		confirmVisible: false
	};

	showConfirmationDialog = () => this.setState({confirmVisible: true});
	hideConfirmationDialog = (result, callback) => () => {
		const {actions} = this.props;

		this.setState({confirmVisible: false}, () => {
			callback && callback();

			const labelCancel = "Abbrechen";
			const labelOK = "Anrufen";

			actions.gtmClickEvent({
				eventAction: `button:${C}:confirmationDialog:${result ? labelOK : labelCancel}`,
				eventLabel: result ? labelOK : labelCancel
			});
		});
	};

	onCall = () => {
		const {T, actions, telno} = this.props;

		window.location.href = `tel:${telno}`;

		actions.showCustomInfo(T(C, "sent"));
	};

	render() {
		const {confirmVisible} = this.state;
		const {T, locale, actions, translations} = this.props;
		const label = _.get(translations, locale, "") || _.get(translations, "de", "");

		const stickerProps = actions.getStickerProperties();
		const {polno = "", plate = ""} = stickerProps;

		const dialogActions = [
			{id: "cancel", secondary: false, children: T(C, "confirmDialog.Cancel"), onClick: this.hideConfirmationDialog(false)},
			{id: "ok", secondary: false, children: T(C, "confirmDialog.OK"), onClick: this.hideConfirmationDialog(true, this.onCall)},
		];

		return (
				<div className={css.viewContainer}>
					<StyledButton
						styled="primary"
						flat
						primary
						onClick={this.showConfirmationDialog}
						data-gtm-action={`button:${C}`}
						data-gtm-label="Assistance anrufen"
            style={{
              fontWeight: "bold"
            }}
					>
						{label}
					</StyledButton>
					<ConfirmationDialog
						id="confirmation-dialog"
						visible={confirmVisible}
						title={T(C, "confirmDialog.title")}
						actions={dialogActions}
						initialFocus="ok"
					>
						{T(C, "confirmDialog.text", {polno, plate})}
					</ConfirmationDialog>
				</div>
		);
	}
}

export default ButtonAssistance;