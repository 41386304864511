import React from "react";
import { Form, Field } from "formik";
import * as Yup from "yup";

import { FormContainer } from "components/forms";
import {
	StyledButton,
	StyledFormTextField,
	StyledFormRadioGroup,
	StyledImagesUploader,
	StyledErrors,
} from "../../../../../../controls";
import { useFormState } from "components/hooks";
import ThemedPageWrapper from "../../ThemedPageWrapper";

const C = "profiles.hdi-at.PageClaim.PageCollision.Object.PageParty";

const PagePartyForm =
	({ actions, item, images, onChanged }) =>
	({ formProps: { values } }) => {
		const controlsPartyHurt = [
			{ label: "ja", value: "yes" },
			{ label: "nein", value: "no" },
		];

		const controlsPartyCause = [
			{ label: "beim Ein- oder Ausparken", value: "parking" },
			{ label: "beim Überholmanöver", value: "overtaking" },
			{ label: "wegen Vorfahrtmissachtung", value: "giveway" },
			{ label: "aus anderen Gründen", value: "other" },
		];

		const partyIsKnown = values.partyKnown === "yes";

		return (
			<ThemedPageWrapper>
				<Form>
					<Field name="errors" showOnlyFirst component={StyledErrors} />
					<Field
						type="text"
						name="partyCause"
						label="Schadenhergang: Kollision"
						component={StyledFormRadioGroup}
						controls={controlsPartyCause}
						onChanged={(value) => onChanged({ ...values, ...value })}
					/>
					{values.partyCause === "other" && (
						<Field
							type="text"
							name="partyReason"
							label="Grund"
							placeholder="Bitte ausfüllen..."
							rows={3}
							component={StyledFormTextField}
						/>
					)}
					<Field
						type="text"
						name="partyKnown"
						label="Ist der Eigentümer des beschädigten Objekts bekannt?"
						component={StyledFormRadioGroup}
						controls={controlsPartyHurt}
						onChanged={(value) => onChanged({ ...values, ...value })}
					/>
					{partyIsKnown && (
						<>
							<Field
								name="images_license"
								label="Foto des Ausweises:"
								actions={actions}
								item={item}
								images={images}
								tag="license"
								component={StyledImagesUploader}
							/>
							<Field
								type="text"
								name="partyName"
								label="Name, Vorname"
								placeholder=""
								component={StyledFormTextField}
							/>
							<Field
								type="text"
								name="partyAddress"
								label="Adresse"
								placeholder=""
								component={StyledFormTextField}
							/>
							<Field
								type="text"
								name="partyTelno"
								label="Telefonnummer"
								placeholder=""
								component={StyledFormTextField}
							/>
							<Field
								type="text"
								name="partyEmail"
								label="Emailadresse"
								placeholder=""
								component={StyledFormTextField}
							/>
						</>
					)}
				</Form>
			</ThemedPageWrapper>
		);
	};

const validationSchema = Yup.object().shape({
	partyKnown: Yup.string().required("Bitte geben Sie an, ob Ihnen der Eigentümer bekannt ist."),
	partyCause: Yup.string().required("Bitte geben Sie einen Grund an."),
	partyReason: Yup.string().when("partyCause", {
		is: "other",
		then: Yup.string().required("Bitte geben Sie einen Grund an."),
	}),
	partyName: Yup.string(),
	partyAddress: Yup.string(),
	partyTelno: Yup.string(),
	partyEmail: Yup.string().trim().email("Bitte geben Sie eine gültige Emailadresse an."),
});

const PageParty = ({ wizardProps: { T, actions }, item, images }) => {
	const [formState, dispatch] = useFormState();
	const { partyKnown, partyName, partyAddress, partyTelno, partyEmail, partyCause } = formState;
	const initialValues = { partyKnown, partyName, partyAddress, partyTelno, partyEmail, partyCause };

	const onChanged = (values) => dispatch({ type: "FORM_CHANGE_VALUES", ...values });
	const FormComponent = PagePartyForm({ actions, item, images, onChanged });

	const onPrevious = () => dispatch({ type: "FORM_GOTO_PREVIOUS_SUBSTEP" });
	const onSubmit = (values) => {
		dispatch({ type: "FORM_SUBMIT_SUBSTEP_COLLISION_OBJECT_PARTY", ...values });
		actions.gtmCustomEvent({ eventAction: `journey:${C}:submit` });
	};

	return (
		<FormContainer
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			FormComponent={FormComponent}
			actionButtons={({ formProps: { submitForm } }) => [
				<StyledButton styled="secondary" key="cancel" onClick={onPrevious}>
					{T(C, "previous")}
				</StyledButton>,
				<StyledButton styled="primary" key="next" onClick={submitForm}>
					{T(C, "next")}
				</StyledButton>,
			]}
		/>
	);
};

export default PageParty;
