import React, { PureComponent } from "react";
import _ from "lib/lodash";

import { withDialog } from "components/hocs";
import { ConfirmationDialog, StarRatingPicker } from "components/controls";

import css from "./FeedbackForm.module.scss";

const C = "profiles.hdi-at.pilot-19.feedbackForm";

const FeedbackDialog = withDialog(
	({ T, id, visible, show, hide, title, children, allRated, ...otherProps }) => {
		const actionTitle = T(C, allRated ? "okButton.label" : "disabledButton.label");

		return (
			<ConfirmationDialog
				id="feedaback-dialog"
				dialogClassName={css.feedbackDialog}
				visible={visible}
				title={title}
				actions={[{ secondary: false, disabled: !allRated, children: actionTitle, onClick: hide }]}
				{...otherProps}
			>
				{children}
			</ConfirmationDialog>
		);
	}
);

class FeedbackForm extends PureComponent {
	state = {
		rating1: "",
	};

	onChangeRatingItem1 = (rating) => this.setState({ rating1: rating.label });

	renderStarIcon = (index, value, name, id) => (
		<i key={`icon_${id}`} className={css.starIcon}>
			&#9733;
		</i>
	);

	getAndResetRatings = () => {
		const ret = [this.state.rating1, this.state.rating2, this.state.rating3];

		this.setState({ rating1: "" });

		return ret;
	};

	componentDidMount() {
		const { onEntry = _.noop } = this.props;

		onEntry();
	}

	render() {
		const { T, visible, onHide, claimId, ...otherProps } = this.props;
		const { rating1 } = this.state;

		const allRated = visible && _.isSet(rating1);
		const handleHide = () => onHide(this.state);

		return (
			<FeedbackDialog
				T={T}
				title={T(C, "title")}
				visible={visible}
				hide={handleHide}
				allRated={allRated}
				{...otherProps}
			>
				<div className={css.container}>
					<p>{T(C, "subtitle", { claimId })}</p>
					<StarRatingPicker
						item={T(C, "item1.label")}
						leftLabel={T(C, "item1.lower")}
						rightLabel={T(C, "item1.upper")}
						labels={["10", "20", "30", "40", "50"]}
						starCount={5}
						starColor="#009c33"
						emptyStarColor="#a9a9a9"
						containerClassName={css.starRatingPickerContainer}
						itemClassName={css.starRatingPickerItem}
						itemTitleClassName={css.starRatingPickerItemTitle}
						ratingClassName={css.starRatingRating}
						renderStarIcon={this.renderStarIcon}
						onChange={this.onChangeRatingItem1}
					/>
				</div>
			</FeedbackDialog>
		);
	}
}

export default FeedbackForm; // withIntl() does not work, otherwise .state is not accessible anymore
