import React, { PureComponent } from "react";

import { withDraggableList } from "components/hocs";
import { DataConsumer } from "components/contexts";

import SlaveNodeContainer from "./SlaveNodeContainer";
import css from "./SlaveNodesList.module.scss";

const DraggableNodesList = withDraggableList({ ListItemComponent: SlaveNodeContainer });

class SlaveNodesList extends PureComponent {
	render() {
		const { keyPrefix, nodeFilters, subnodeFilters, containerActions } = this.props;

		console.log("HERE", this.props);

		return (
			<DataConsumer>
				{({ nodes, subnodes, actions, isUploading }) => (
					<div className={css.list}>
						<DraggableNodesList
							items={nodes.items}
							actions={actions}
							subnodes={subnodes}
							onDragEnd={actions.updateNodesOrder}
							keyPrefix={keyPrefix}
							nodeFilters={nodeFilters}
							subnodeFilters={subnodeFilters}
							isUploading={isUploading}
							containerActions={containerActions}
							disabled
						/>
					</div>
				)}
			</DataConsumer>
		);
	}
}

export default SlaveNodesList;
