import React from "react";
import { Form, Field } from "formik";
import _ from "lib/lodash";

import { FormContainer } from "components/forms";
import { StyledButton, StyledTable, StyledFormImageList } from "../../../../../../controls";
import { useFormState } from "components/hooks";
import ThemedPageWrapper from "../../ThemedPageWrapper";

const C = "profiles.hdi-at.PageClaim.PageCollision.Car.PageOverview";

const PageOverviewForm =
	({
		claimId,
		categoryText,
		subcategoryText,
		polno,
		owner,
		driverName,
		driverPlate,
		driverTelno,
		driverEmail,
		datetime,
		location,
		damagesText,
		comments,
		partyKnown,
		partyPlate,
		partyName,
		partyAddress,
		partyTelno,
		partyEmail,
		partyInsurer,
		partyCauseText,
		partyKnownText,
		images,
	}) =>
	() => {
		const isPartyKnown = partyKnown !== "unknown";

		const table = _.compact([
			["Versicherungsnehmer", owner],
			["", polno],
			["", ""],
			!!driverPlate && ["Fahrer", driverPlate],
			!!driverName && ["", driverName],
			!!driverTelno && ["", driverTelno],
			!!driverEmail && ["", driverEmail],
			["", ""],
			["Schadenart", categoryText + " " + subcategoryText],
			["am", new Date(datetime).toLocaleDateString("de-DE")],
			["um", new Date(datetime).toLocaleTimeString("de-DE")],
			["am Ort", location.label],
			["", ""],
			!!partyCauseText && ["Kollisionsgrund", partyCauseText],
			!!damagesText && ["Schäden", damagesText],
			!!damagesText && ["", ""],
			!!comments && ["Bemerkungen", comments],
			!!comments && ["", ""],
			!!partyKnownText && ["Andere Person", partyKnownText],
			!!partyPlate && ["", "Kennzeichen " + partyPlate],
			isPartyKnown && !!partyName && ["", partyName],
			isPartyKnown && !!partyAddress && ["", partyAddress],
			isPartyKnown && !!partyTelno && ["", partyTelno],
			isPartyKnown && !!partyEmail && ["", partyEmail],
			isPartyKnown && !!partyInsurer && ["", partyInsurer],
			["", ""],
		]);

		const hasImages = {
			license: _.filter(images, { properties: { tag: "license" } }).length > 0,
			report: _.filter(images, { properties: { tag: "report" } }).length > 0,
			damage: _.filter(images, { properties: { tag: "damage" } }).length > 0,
		};

		return (
			<ThemedPageWrapper>
				<Form>
					<div>
						Schadenmeldung Nr. <strong>{claimId}</strong>
					</div>
					<StyledTable>
						<tbody>
							{table.map((row, idx) => (
								<tr key={idx}>
									<td>{row[0]}</td>
									<td>{row[1]}</td>
								</tr>
							))}
						</tbody>
					</StyledTable>
					{hasImages["license"] && (
						<Field
							label="Foto(s) des Ausweises"
							images={images}
							tag="license"
							component={StyledFormImageList}
						/>
					)}
					{hasImages["report"] && (
						<Field
							label="Foto(s) Unfallreport"
							images={images}
							tag="report"
							component={StyledFormImageList}
						/>
					)}
					{hasImages["damage"] && (
						<Field
							label="Foto(s) der Schäden"
							images={images}
							tag="damage"
							component={StyledFormImageList}
						/>
					)}
				</Form>
			</ThemedPageWrapper>
		);
	};

const PageOverview = ({ wizardProps: { T }, submitForm, isSubmitting, images, ...otherProps }) => {
	const [formState, dispatch] = useFormState();
	const FormComponent = PageOverviewForm({ ...formState, images });

	const onPrevious = () => dispatch({ type: "FORM_GOTO_PREVIOUS_SUBSTEP" });

	return (
		<FormContainer
			onSubmit={submitForm}
			FormComponent={FormComponent}
			actionButtons={({ formProps: { submitForm } }) => [
				<StyledButton styled="secondary" key="cancel" onClick={onPrevious}>
					{T(C, "previous")}
				</StyledButton>,
				<StyledButton styled="primary" key="next" isLoading={isSubmitting} onClick={submitForm}>
					{T(C, "next")}
				</StyledButton>,
			]}
		/>
	);
};

export default PageOverview;
